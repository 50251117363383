import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { CommentModule } from '@aifs-shared/comments/comment.module';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { ApplicantRoutingModule } from './applicant-routing.module';
import { ApplicantSearchComponent } from './applicant-search.component';
import { NgbdMissingItemSortableHeader, NgbdSortableHeader } from './NgbdSortableHeader';
import { ApplicantDetailComponent } from './applicant-detail.component';
import { ApplicantResultsTableComponent } from './applicant-results.table.component';
import { ApplicantSidebarComponent } from './applicant-sidebar.component';
import { ApplicantProfileImageComponent } from './applicant-profile-image.component';
import { ApplicationSummaryComponent } from './application-summary.component';
import { ApplicationActionsComponent } from './application-actions.component';
import { ApplicationInterviewComponent } from './application-interview.component';
import { ApplicationReferenceComponent } from './application-reference.component';
import { ApplicationCommentsComponent } from './application-comments.component';
import { ApplicationPaymentComponent } from './application-payment.component';
import { ApplicationDetailsComponent } from './application-details.component';
import { ApplicationPlacementComponent } from './application-placement.component';
import { ApplicationVisaComponent } from './application-visa.component';
import { ApplicationIssuesComponent } from './application-issues.component';
import { ApplicationMedicalComponent } from './application-medical.component';
import { MedicalFormComponent } from './medical-form.component';
import { ApplicationPoliceCheckComponent } from './application-police-check.component';
import { InterviewFormComponent } from './interview-form.component';
import { ApplicationSummaryFormComponent } from './application-summary-form.component';
import { TopMenuModule } from '@aifs-shared/menu/top-menu.module';
import { BookmarkModule } from '../bookmark/bookmark.module';
import { SearchModule } from '@aifs-shared/search/search.module';
import { ApplicationFlightComponent } from './application-flight.component';
import { ApplicationOutboundFlightComponent } from './application-outbound-flight.component';
import { ApplicationReturnFlightComponent } from './application-return-flight.component';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { InterviewCompleteFormComponent } from './interview-complete-form.component';
import { InterviewReportFormComponent } from './interview-report-form.component';
import { VisaModule } from '../visa/visa.module';
import { SpinnerModule } from '@aifs-shared/spinner/spinner.module';
import { ViewMyApplicationsComponent } from './view-my-applications.component';
import { ViewMissingItemsComponent } from './view-missing-items.component';
import { TableMissingItemsComponent } from './table-missing-items.component';
import { ApplicationActionsProposeInterviewComponent } from './application-actions-propose-interview.component';
import { ViewAuthenticationRequiredComponent } from './view-authentication-required.component';
import { TableReferenceItemsComponent } from './table-reference-items.component';
import { DateTimePickersModule } from '@aifs-shared/date-time-pickers/date-time-pickers.module';
import { ProposeInterviewComponent } from './interview/propose-interview.component';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';
import { RemoveRejectApplicantComponent } from './remove-reject/remove-reject.component';

@NgModule({
    declarations: [
        ApplicantSearchComponent,
        ApplicantResultsTableComponent,
        NgbdSortableHeader,
        NgbdMissingItemSortableHeader,
        ApplicantDetailComponent,
        ApplicantSidebarComponent,
        ApplicantProfileImageComponent,
        ApplicationSummaryComponent,
        ApplicationActionsComponent,
        ApplicationActionsProposeInterviewComponent,
        ApplicationInterviewComponent,
        ApplicationReferenceComponent,
        ApplicationCommentsComponent,
        ApplicationPaymentComponent,
        ApplicationDetailsComponent,
        ApplicationPlacementComponent,
        ApplicationVisaComponent,
        ApplicationFlightComponent,
        ApplicationOutboundFlightComponent,
        ApplicationReturnFlightComponent,
        ApplicationIssuesComponent,
        ApplicationMedicalComponent,
        ApplicationPoliceCheckComponent,
        ApplicationSummaryFormComponent,
        RemoveRejectApplicantComponent,
        InterviewFormComponent,
        InterviewCompleteFormComponent,
        InterviewReportFormComponent,
        MedicalFormComponent,
        ProposeInterviewComponent,
        ViewMyApplicationsComponent,
        ViewMissingItemsComponent,
        ViewAuthenticationRequiredComponent,
        TableMissingItemsComponent,
        TableReferenceItemsComponent,
    ],
    imports: [
        CommonModule,
        ApplicantRoutingModule,
        BookmarkModule,
        CommentModule,
        MetaFormModule,
        NgbModule,
        PipesModule,
        SearchModule,
        SpinnerModule,
        TopMenuModule,
        UploadModule,
        VisaModule,
        FormsModule,
        DateTimePickersModule
    ],
    exports: [
        ApplicantSearchComponent
    ]
})
export class ApplicantModule { }
