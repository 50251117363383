

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthDayPickerComponent } from './month-day-picker.component';
import { FormsModule } from '@angular/forms';
import { TimePickerComponent } from './time-picker.component';


@NgModule({
    // Inputs
    imports: [
        CommonModule,        
        FormsModule
    ],
    // Outputs
    declarations: [
        MonthDayPickerComponent,
        TimePickerComponent
    ],
    // Exports - if we export a component from here, we will probably import it into another module (either feature or root)
    exports: [
        MonthDayPickerComponent,
        TimePickerComponent
    ]
})
export class DateTimePickersModule {

}