import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '@aifs-shared/pipe/pipes.module';

import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

import { VisaService } from './visa.service';
import { VisaFormComponent } from './visa-form.component';
import { DS2019StatusComponent } from './ds2019-status.component';
import { VisaApplicantInfoComponent } from './visa-applicant-info.component';
import { UserFormModule } from '@aifs-shared/forms/user-form.module';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule, 
        MetaFormModule,
        NgbModule,
        PipesModule,
        UserFormModule
    ],
    declarations: [
        VisaFormComponent,
        DS2019StatusComponent,
        VisaApplicantInfoComponent
    ],
    exports: [
        VisaFormComponent,
        DS2019StatusComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        VisaService
    ]
})
export class VisaModule { }
